import {DateFormatter} from '@wix/wix-events-commons-statics'
import * as classNames from 'classnames'
import * as React from 'react'
import {CountdownProps} from '.'
import * as sc from '../../classes.scss'
import * as s from './countdown.scss'

export const Countdown = ({event, t, onImage, isMobile}: CountdownProps) => {
  const {startDate, timeZoneId} = event.scheduling.config

  const getDaysLeft = () => {
    const now = DateFormatter.now(timeZoneId)
    const start = DateFormatter.getMomentDate(startDate, timeZoneId)
    const hours = Math.floor(DateFormatter.getDiffInHours(start, now))
    const days = Math.ceil((hours - start.hour()) / 24)

    return days < 0 ? 0 : days
  }

  const daysLeft = getDaysLeft()
  const text = daysLeft === 1 ? t('countdownOneDay') : t('countdownManyDays', {daysLeft})

  return (
    <div
      className={classNames(sc.textLineHeight, s.root, {
        [s.onImage]: onImage,
        [s.mobile]: isMobile,
      })}
      data-hook="ev-countdown"
    >
      {text}
    </div>
  )
}
