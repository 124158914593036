import * as classNames from 'classnames'
import * as React from 'react'
import {CardProps} from '.'
import {getListAlignmentClassName} from '../../../selectors/classes'
import {getImageStyle} from '../../../utils/image'
import {FullDateLocation} from '../../full-date-location'
import {Members} from '../../members'
import {RsvpButton} from '../../rsvp-button'
import {ShortDateLocation} from '../../short-date-location'
import {SocialBar} from '../../social-bar'
import * as s from './card.scss'

const IMAGE_SIZE = {
  '1:1': {
    width: 768,
    height: 768,
  },
  '16:9': {
    width: 768,
    height: 432,
  },
}

export class Card extends React.Component<CardProps> {
  getImageStyle() {
    const {event, imageRatio, imageOpacity} = this.props
    const container = IMAGE_SIZE[imageRatio]

    if (event.mainImage && event.mainImage.id) {
      return getImageStyle(event.mainImage, container, imageOpacity)
    }

    return {}
  }

  getContainerClasses() {
    const {
      showImage,
      showFullDate,
      showLocation,
      showDescription,
      showSocialBar,
      showMembers,
      membersEnabled,
      hideAdditionalComponents,
      alignment,
    } = this.props

    return classNames(s.container, s[getListAlignmentClassName(alignment)], {
      [s.noImage]: !showImage,
      [s.noDateLocation]: !showFullDate && !showLocation,
      [s.noDescription]: !showDescription,
      [s.noSocialBar]: !showSocialBar,
      [s.noAdditionalComponents]: hideAdditionalComponents,
      [s.noMembers]: !showMembers,
      [s.membersEnabled]: membersEnabled,
    })
  }

  render() {
    const {event, showDate, showVenue, showFullDate, showLocation, showMembers, alignment, fullLocale, t} = this.props

    return (
      <div className={this.getContainerClasses()}>
        <div className={s.imageContainer} data-hook="image">
          <div className={classNames(s.imageOverlay, s.evCardImageBackgroundColor)} />
          <div className={s.image} style={this.getImageStyle()} />
        </div>
        <div className={classNames(s.content, s.evCardContentColor)} data-hook="content">
          <div className={s.title} data-hook="title">
            {event.title}
          </div>
          <div className={classNames(s.shortDateLocation, s.evCardContentFont)} data-hook="short-date-location">
            <ShortDateLocation
              event={event}
              showDate={showDate}
              showLocation={showVenue}
              alignment={alignment}
              fullLocale={fullLocale}
            />
          </div>
          <div className={classNames(s.details, s.evCardContentFont)} data-hook="details">
            <div className={s.dateLocation}>
              <FullDateLocation
                event={event}
                showDate={showFullDate}
                showLocation={showLocation}
                fullLocale={fullLocale}
              />
            </div>
            {event.description ? (
              <div className={s.description} data-hook="description">
                {event.description}
              </div>
            ) : null}
            <div className={s.socialBar}>
              <SocialBar t={t} event={event} />
            </div>
          </div>
        </div>
        {showMembers ? (
          <div className={s.members} data-hook="members">
            <Members event={event} />
          </div>
        ) : null}
        <div className={s.buttonSection} data-hook="button-section">
          <RsvpButton event={event} />
        </div>
      </div>
    )
  }
}
